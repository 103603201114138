import { TStripUndefined } from '@/types/generics'
import {
  Components,
  Theme,
  ButtonProps,
  ComponentsVariants,
} from '@mui/material'
import { colors } from 'tailwind.config'

//TODO
//button variant found on /documentation text-dark-grey hover:bg-grey hover:text-white
//sx={{ borderColor: '#758E99' }}

type MUIButtonVariants = TStripUndefined<
  ComponentsVariants<Theme>['MuiButton']
>[0]

type MyButtonVariants = Omit<MUIButtonVariants, 'props'> & {
  props: Omit<ButtonProps, 'color'> & {
    color?: ButtonProps['color'] | 'special'
  }
  style: MUIButtonVariants['style']
}
const test: MyButtonVariants = {
  props: { variant: 'contained', color: 'special' },
  style: ({ theme }) => {
    return {
      backgroundColor: colors.blue['600'],
      ':hover': {
        backgroundColor: colors.blue['400'],
      },
      color: colors.white,
    }
  },
}

type MUIButton = Omit<
  TStripUndefined<Components<Theme>['MuiButton']>,
  'variants'
> & { variants: MyButtonVariants[] }
// type MUIButton = Components<Theme>['MuiButton'] & {variants: MyButtonVariants[]}
// type MUIButton = {
//   defaultProps: ButtonProps
//   styleOverrides: Components<Theme>['MuiButton']['styleOverrides']
// }

// alt variant cu  sx={{ p: 0 }}, pentru SignInForm
export const MuiButton: MUIButton = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
    color: 'secondary',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      '&.MuiButton-root': {
        fontWeight: '600',
        textTransform: 'none',
        borderRadius: '100px',
        fontFamily: 'inherit',
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' },
      style: () => {
        return {
          backgroundColor: colors.blue['600'],
          ':hover': {
            backgroundColor: colors.blue['400'],
          },
          color: colors.white,
        }
      },
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: () => {
        return {
          backgroundColor: colors.blue['600'],
          ':hover': {
            backgroundColor: '#4CB8E5',
          },
          color: colors.white,
        }
      },
    },
    {
      props: { variant: 'contained', color: 'warning' },
      style: () => {
        return {
          backgroundColor: colors.white,
          ':hover': {
            backgroundColor: '#F0F3F4',
          },
          color: '#485961',
        }
      },
    },
    {
      props: { variant: 'outlined', color: 'info' },
      style: () => {
        return {
          borderColor: colors.neutral[400],
          color: colors.neutral[600],
          ':hover': {
            backgroundColor: colors.neutral[100],
          },
        }
      },
    },
    {
      props: { variant: 'outlined', color: 'warning' },
      style: () => {
        return {
          borderColor: colors.white,
          color: colors.white,
          ':hover': {
            backgroundColor: colors.white,
            borderColor: colors.white,
            color: '#485961',
          },
        }
      },
    },
    {
      props: { variant: 'text', color: 'warning' },
      style: () => {
        return {
          borderColor: '#758E99',
          backgroundColor: colors.alert.error,
          color: colors.white,
          ':hover': {
            backgroundColor: colors.alert.error,
            borderColor: colors.white,
            color: colors.white,
          },
        }
      },
    },
    {
      props: { variant: 'text', color: 'primary' },
      style: () => {
        return {
          borderColor: 'none',
          backgroundColor: 'none',
          color: colors.blue['600'],
          ':hover': {
            backgroundColor: '#0099DA0D',
            borderColor: 'none',
          },
        }
      },
    },
    {
      props: { variant: 'text', color: 'secondary' },
      style: () => {
        return {
          borderColor: 'none',
          backgroundColor: 'none',
          color: colors.neutral['600'],
          ':hover': {
            backgroundColor: '#161a1e0f',
            borderColor: 'none',
          },
        }
      },
    },
    {
      props: { variant: 'switch', color: 'secondary' },
      style: () => {
        return {
          borderColor: 'none',
          backgroundColor: 'none',
          color: colors.neutral['700'],
          ':hover': {
            backgroundColor: colors.white,
            borderColor: 'none',
            color: colors.neutral['500'],
          },
        }
      },
    },
    {
      props: { variant: 'outlined', color: 'success' },
      style: ({ theme }) => {
        const { palette } = theme
        return {
          borderColor: '#758E99',
          color: '#485961',
          ':hover': {
            backgroundColor: '#758E99',
            borderColor: '#758E99',
            color: colors.white,
          },
        }
      },
    },
    {
      props: { size: 'small' },
      style: {
        fontSize: '13px',
        lineHeight: '20px',
        padding: '6px 20px',
      },
    },
    {
      props: { size: 'medium' },
      style: {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '10px 20px',
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: '16px',
        lineHeight: '24px',
        padding: '15px 20px',
      },
    },
    // for the outlined btns, because of the border we need to
    // substract 1px from the padding so the width / height remains the same
    {
      props: { variant: 'outlined', size: 'small' },
      style: {
        padding: '5px 19px',
      },
    },
    {
      props: { variant: 'outlined', size: 'medium' },
      style: {
        padding: '9px 14px',
      },
    },
    {
      props: { variant: 'outlined', size: 'large' },
      style: {
        padding: '14px 19px',
      },
    },
    {
      props: { variant: 'contained', color: 'special' },
      style: () => {
        return {
          backgroundColor: colors.blue['600'],
          backgroundImage: `linear-gradient(95deg, ${colors.blue['800']} 1%, ${colors.green['800']} 99%)`,
          ':hover': {
            '&::before': {
              opacity: 1,
            },
          },
          zIndex: 1,
          position: 'relative',
          transition: 'background 0.3s ease',
          color: colors.white,
          overflow: 'hidden',
          '::before': {
            position: 'absolute',
            content: "''",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage: `linear-gradient(95deg, ${colors.green['700']} 1%, ${colors.blue['800']} 99%)`,
            zIndex: '-1',
            transition: 'opacity 0.3s linear',
            opacity: 0,
          },
        }
      },
    },
  ],
}
