'use client'

import {
  isServer,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { FeatureToggle } from '@/components/FeatureFlags/FeatureToggleProvider'
import { featureFlags } from '@/components/FeatureFlags/utils'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import config from '@/lib/config'
import useAnalyticsInit from '@/hooks/useAnalyticsInit'
import { AppProvider } from '@/components/Providers/AppProvider'

type Props = {
  env: {
    HOTJAR_ID: number
    HOTJAR_VERSION: number
  }
  children?: React.ReactNode
}

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
        refetchOnMount: true,
      },
    },
  })
}

let browserQueryClient: QueryClient | undefined = undefined

function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient()
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

export const Providers = ({ children, env }: Props) => {
  useAnalyticsInit({
    hotjarId: env.HOTJAR_ID,
    hotjarVersion: env.HOTJAR_VERSION,
    amplitudeId: config.NEXT_PUBLIC_AMPLITUDE_API_KEY,
    isDev: config.isDev(),
  })
  const queryClient = getQueryClient()
  return (
    <FeatureToggle enabledFeatures={featureFlags}>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          {/* <GlobalDialogsProvider>{children}</GlobalDialogsProvider> */}
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppProvider>
    </FeatureToggle>
  )
}
