import { Components } from '@mui/material'
import { MuiButton } from './themed-components/button.modifier'
import { MuiTextField } from './themed-components/input.modifier'
import { MuiAlert } from './themed-components/alert.modifier'
import { Theme } from '@mui/material/styles'

export const components: Components<Theme> = {
  MuiButton: MuiButton as Components<Theme>['MuiButton'],
  MuiTextField,
  MuiAlert,
}
